<script setup lang="ts">
import { CheckoutSteps, useCheckoutStore } from '~/store/checkoutStore'
import { useNewSettingsStore } from '~/store/newSettingsStore'

const props = defineProps<{
  hasPaymentConfirmationPending?: boolean
}>()

const checkoutStore = useCheckoutStore()
const settings = useNewSettingsStore()
const { countryPhoneNumber } = storeToRefs(settings)

const isStep1Active = computed(() => checkoutStore.checkoutStep === CheckoutSteps.Checkout)

const isStep2Active = computed(
  () => checkoutStore.checkoutStep === CheckoutSteps.Details || props.hasPaymentConfirmationPending,
)

const isStep3Active = computed(
  () => checkoutStore.checkoutStep === CheckoutSteps.Payment && !props.hasPaymentConfirmationPending,
)

// todo: enable trackings: Google, Bingh, Facebook, Ometria, SessionCam
</script>

<template>
  <div class="w-full border-4 border-brand border-x-0 border-b-0 flex flex-row justify-center">
    <div class="px-10 md:px-32">
      <div class="w-full flex flex-col justify-center md:hidden my-2">
        <span class="w-full text-center mb-0 text-[14px] text-gray-800 font-semibold italic leading-[130%]">
          {{ $t('contact-need-help', 'Need help completing your order?') }}
        </span>
        <a
          class="w-full text-center mb-0 text-[14px] text-gray-800 font-normal italic leading-[130%] no-underline"
          :href="`tel:${countryPhoneNumber}`"
        >
          {{ $t('call-us', 'Call us') }} {{ countryPhoneNumber }}
        </a>
      </div>
      <ul class="w-full flex flex-row flex-nowrap my-0 md:mb-1">
        <li
          class="px-3 py-3 w-1/3 hidden md:flex bg-darkLighter-500 shadow-inner bg-rules"
          :class="{ '!bg-[#ffc13a] active-shadow': isStep1Active }"
          style="background-image: url('/images/img-2018/Checkout/Phase_ShoppingBag.png')"
        >
          <p class="uppercase text-[#333] ml-10 my-0" :class="{ 'font-bold': isStep1Active }">
            {{ $t('shopping-bag', 'Shopping Bag') }}
          </p>
        </li>
        <li
          class="px-3 py-3 w-1/3 hidden md:flex bg-darkLighter-500 shadow-inner bg-rules"
          :class="{ '!bg-[#ffc13a] active-shadow': isStep2Active }"
          style="background-image: url('/images/img-2018/Checkout/Phase_Checkout.png')"
        >
          <p class="uppercase text-[#333] ml-10 my-0" :class="{ 'font-bold': isStep2Active }">
            {{ $t('checkout', 'Checkout') }}
          </p>
        </li>
        <li
          class="px-3 py-3 w-full bg-darkLighter-500 shadow-inner md:w-1/3 bg-rules"
          :class="{ '!bg-[#ffc13a] active-shadow': isStep3Active }"
          style="background-image: url('/images/img-2018/Checkout/Phase_OrderConfirmSelected.png')"
        >
          <p class="uppercase ml-10 my-0" :class="{ 'font-bold': isStep3Active }">
            {{ $t('order-confirmation', 'Order Confirmation') }}
          </p>
        </li>
      </ul>
      <slot />
    </div>
  </div>
</template>
<style scoped>
.active-shadow {
  @apply shadow-['0_2px_10px_#aaa'];
}
.bg-rules {
  background-repeat: no-repeat;
  background-position: 8px;
}
</style>
